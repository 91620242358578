import { render, staticRenderFns } from "./CreateWorkOrder.vue?vue&type=template&id=6b8367ce&scoped=true&"
import script from "./CreateWorkOrder.vue?vue&type=script&lang=js&"
export * from "./CreateWorkOrder.vue?vue&type=script&lang=js&"
import style0 from "./CreateWorkOrder.vue?vue&type=style&index=0&id=6b8367ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8367ce",
  null
  
)

export default component.exports